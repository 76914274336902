import React from 'react'

import articles from '../../../assets/images/png/startupLibraryPage/Articles.png'
import books from '../../../assets/images/png/startupLibraryPage/Books.png'
import time from '../../../assets/images/png/startupLibraryPage/TimeCircle.png'
import videos from '../../../assets/images/png/startupLibraryPage/Play.png'
import profile from '../../../assets/images/png/startupLibraryPage/Profile.png'
import arrow from '../../../assets/images/png/startupLibraryPage/arrowLine.png'

import * as styles from './styles.module.scss'

const Articles = [
  {
    id: 13543635634,
    title: 'How to Find the Right Co-Founder.',
    text:
      'YC Partner Harj Taggar shares advice on how to find the right co-founder for your startup.',
    time: '8 min to read',
    author: 'Harj Taggar',
    button: arrow,
    link:
      'https://www.ycombinator.com/library/8h-how-to-find-the-right-co-founder',
  },
  {
    id: 239769678,
    title: '5 Expert Tips on How to Choose a Co-Founder.',
    text:
      'Here’s some advice from business leaders - on choosing a co-founder for new entrepreneurs.',
    time: '11 min to read',
    author: 'Bryan Janeczko',
    button: arrow,
    link: 'https://www.entrepreneur.com/article/352916',
  },
  {
    id: 337457456745,
    title: 'How to Build a Dream Team for Your Startup.',
    text:
      'Creating a team should be one of the most important challenges for you.',
    time: '7 min to read',
    author: 'Igor Izraylevych',
    button: arrow,
    link:
      'https://igorizraylevych.medium.com/how-to-build-a-dream-team-for-your-startup-72d182cee2ca',
  },
  {
    id: 43543645867,
    title: 'How to build a startup team.',
    text: 'Building a cohesive team in a fast-growing company.',
    time: '5 min to read',
    author: 'Firmin Zocchetto',
    button: arrow,
    link: 'https://www.fastcompany.com/90345681/how-to-build-a-startup-team',
  },
]

const Books = [
  {
    id: 1367546865756,
    title: 'High Output Management.',
    text: 'How to build and run a company.',
    time: '272 pages to read',
    author: 'Andrew S.Grove',
    button: arrow,
    link:
      'https://www.amazon.com/gp/product/0679762884/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0679762884&linkCode=as2&tag=ericjorg-20&linkId=MSKM7VQ5KG7SEES6',
  },
  {
    id: 236574564756,
    title: 'The Alliance.',
    text: 'Managing talent in the networked age.',
    time: '224 pages to read',
    author: 'Ben Casnocha',
    button: arrow,
    link:
      'https://www.amazon.com/gp/product/1625275773/ref=as_li_qf_sp_asin_il_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1625275773&linkCode=as2&tag=ericjorg-20&linkId=UW63SWVM6YQEYT5T',
  },
]

const Videos = [
  {
    id: 1356456754,
    title: 'Hiring and building a team for early stage startups.',
    text: 'Team building for early stage startups.',
    time: '40 min to watch',
    author: 'Kristjan Oro',
    button: arrow,
    link: 'https://www.youtube.com/watch?v=zsw6Z6BpPh4',
  },
  {
    id: 23564568687,
    title: 'Building a Team.',
    text: 'The key to success for a startup.',
    time: '17 min to watch',
    author: 'Alain Bloch',
    button: arrow,
    link:
      'https://www.coursera.org/lecture/tech-startup/3-4-building-a-team-the-key-to-success-for-a-startup-mBPwI',
  },
]

const BuildTeam = ({ currentElement }) => {
  return (
    <div className={styles.startupLibraryBlock}>
      <div className={styles.startupLibraryBlockArticles} id={'build-a-team'}>
        <div
          className={styles.startupLibraryBlockArticlesPage}
          ref={currentElement}
        >
          <div>
            <div>
              <h2>Build a Team</h2>
              <div className={styles.startupLibraryBlockArticlesPageTitle}>
                <img src={articles} alt="Articles" />
                <h3>Articles</h3>
              </div>
            </div>
            {Articles?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={books} alt="Books" />
              <h3>Books</h3>
            </div>
            {Books?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={videos} alt="Videos" />
              <h3>Videos</h3>
            </div>
            {Videos?.map((el) => (
              <div key={el.id}  className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuildTeam
