import React from 'react'

import articles from '../../../assets/images/png/startupLibraryPage/Articles.png'
import books from '../../../assets/images/png/startupLibraryPage/Books.png'
import time from '../../../assets/images/png/startupLibraryPage/TimeCircle.png'
import videos from '../../../assets/images/png/startupLibraryPage/Play.png'
import profile from '../../../assets/images/png/startupLibraryPage/Profile.png'
import arrow from '../../../assets/images/png/startupLibraryPage/arrowLine.png'

import * as styles from './styles.module.scss'

const Articles = [
  {
    id: 1342134,
    title: 'How to start a startup.',
    text: 'Makes you aware of the basics when launching a startup.',
    time: '8 min to read',
    author: 'Mariia Lozhka',
    button: arrow,
    link:
      'https://lanars.com/blog/how-to-start-a-startup-make-a-business-step-by-step',
  },
  {
    id: 224355,
    title: 'Startup playbook.',
    text: 'A bunch of advice from the most known businessmen.',
    time: '4 min to read',
    author: 'Aleksandr Sobchak',
    button: arrow,
    link: 'https://habr.com/ru/post/296328/',
  },
  {
    id: 356435,
    title: '57 Startup Lessons.',
    text: 'Lessons drawn from the 4 years of experience.',
    time: '10 min to read',
    author: 'Slava Akhmechet',
    button: arrow,
    link:
      'https://thenextweb.com/news/57-startup-lessons-ive-learned-the-hard-way',
  },
  {
    id: 4531321235,
    title: 'Startup Advice.',
    text: '95 short pieces of advice from the startups guru.',
    time: '4 min to read',
    author: 'Geoff Ralston',
    button: arrow,
    link:
      'https://www.ycombinator.com/library/4D-yc-s-essential-startup-advice',
  },
]

const Books = [
  {
    id: 123462346,
    title: 'The Startup Owner’s Manual.',
    text:
      'The startup advisor and founder has described his lean startup strategy for startups.',
    time: '608 pages to read',
    author: 'Bob Dorf',
    button: arrow,
    link:
      'https://www.amazon.com/Startup-Owners-Manual-Step-Step/dp/0984999302',
  },
  {
    id: 246362366234,
    title: 'Zero to One.',
    text: 'Notes on startups, or how to build the future.',
    time: '224 pages to read',
    author: 'Peter Thiel',
    button: arrow,
    link: 'https://www.amazon.com/Zero-One-Notes-Startups-Future/dp/0804139296',
  },
]

const Videos = [
  {
    id: 1235231,
    title: 'How to Start a Startup.',
    text:
      'The startup advisor has described his lean startup strategy for startups.',
    time: '44 min to watch',
    author: 'Sam Altman',
    button: arrow,
    link: 'https://www.youtube.com/watch?v=CBYhVcO4WgI',
  },
  {
    id: 24356345634,
    title: 'Tech Startup: How to start a technology company.',
    text:
      'The speaker informs us about the importance of learning programming, and the possibilities of gaining a competitive edge.',
    time: '10 min to watch',
    author: 'Magnus Lysfjord ',
    button: arrow,
    link: 'https://www.youtube.com/watch?v=8kFjDUZqR6A',
  },
]

const StartHere = ({ currentElement }) => {
  return (
    <div className={styles.startupLibraryBlock}>
      <div className={styles.startupLibraryBlockArticles} id={'start-here'}>
        <div
          className={styles.startupLibraryBlockArticlesPage}
          ref={currentElement}
        >
          <div>
            <div>
              <h2>Start Here</h2>
              <div className={styles.startupLibraryBlockArticlesPageTitle}>
                <img src={articles} alt="Articles" />
                <h3>Articles</h3>
              </div>
            </div>
            {Articles?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={books} alt="Books" />
              <h3>Books</h3>
            </div>
            {Books?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={videos} alt="Videos" />
              <h3>Videos</h3>
            </div>
            {Videos?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartHere
