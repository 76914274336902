import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'

import BuildTeam from './BuildTeam'
import StartHere from './StartHere'
import LaunchIdea from './LaunchIdea'
import CreateMVP from './CreateMVP'
import GrowStartup from './GrowStartup'
import MakeMoney from './MakeMoney'
import RaiseFunds from './RaiseFunds'
import useVisibility from './StepperHook'

import * as styles from './styles.module.scss'

const Steps = [
  {
    id: 1,
    title: 'Start Here',
    link: '/startup-library/#start-here',
  },
  {
    id: 2,
    title: 'Launch an Idea',
    link: '/startup-library/#launch-an-idea',
  },
  {
    id: 3,
    title: 'Build a Team',
    link: '/startup-library/#build-a-team',
  },
  {
    id: 4,
    title: 'Create an MVP',
    link: '/startup-library/#create-an-mvp',
  },
  {
    id: 5,
    title: 'Grow the Startup',
    link: '/startup-library/#grow-the-startup',
  },
  {
    id: 6,
    title: 'Make Money',
    link: '/startup-library/#make-money',
  },
  {
    id: 7,
    title: 'Raise Funds',
    link: '/startup-library/#raise-funds',
  },
]

const StartupLibraryBlockSteps = () => {
  const [isVisible, currentElement] = useVisibility(0)
  const [isVisible2, currentElement2] = useVisibility(0)
  const [isVisible3, currentElement3] = useVisibility(0)
  const [isVisible4, currentElement4] = useVisibility(0)
  const [isVisible5, currentElement5] = useVisibility(0)
  const [isVisible6, currentElement6] = useVisibility(0)
  const [isVisible7, currentElement7] = useVisibility(0)
  const [newClass, setNewClass] = useState(null)
  const [activeFixed, setActiveFixed] = useState(false)
  const [activeAbsolut, setActiveAbsolut] = useState(false)

  const windowCheck = typeof window !== 'undefined'
  const topHeight = useRef(null)

  const handleScroll = () => {
    const { top } = topHeight.current.getBoundingClientRect()

    if (top < 0 && top > -12288) {
      setActiveFixed(true)
    } else {
      if (top < -12288) {
        setActiveFixed(false)
        setActiveAbsolut(true)
      } else {
        setActiveAbsolut(false)
        setActiveFixed(false)
      }
    }
  }

  useEffect(() => {
    windowCheck && window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const newClassItem = (classArray) =>
    setNewClass(
      classArray.indexOf(true) === -1 ? 1 : classArray.indexOf(true) + 2
    )

  useEffect(() => {
    newClassItem([
      isVisible2,
      isVisible3,
      isVisible4,
      isVisible5,
      isVisible6,
      isVisible7,
    ])
  }, [isVisible2, isVisible3, isVisible4, isVisible5, isVisible6, isVisible7])

  return (
    <div className={styles.startupLibraryBlock} ref={topHeight}>
      <div className={styles.startupLibraryBlockParts}>
        <div className={styles.startupLibraryBlockPartsSteps}>
          <div
            className={`${styles.startupLibraryBlockPartsStepsNecessary}
            ${
              (activeFixed &&
                styles.startupLibraryBlockPartsStepsNecessaryFixed) ||
              (activeAbsolut &&
                styles.startupLibraryBlockPartsStepsNecessaryAbsolut)
            }`}
          >
            <h3>Necessary steps:</h3>
            {Steps?.map(({ title, link, id }) => (
              <Link
                key={id}
                to={link}
                className={
                  id === newClass &&
                  styles.startupLibraryBlockPartsStepsNecessaryTab
                }
              >
                {title}
              </Link>
            ))}
          </div>
        </div>
        <div>
          <StartHere currentElement={currentElement} isVisible={isVisible} />
          <LaunchIdea currentElement={currentElement2} isVisible={isVisible2} />
          <BuildTeam currentElement={currentElement3} isVisible={isVisible3} />
          <CreateMVP currentElement={currentElement4} isVisible={isVisible4} />
          <GrowStartup
            currentElement={currentElement5}
            isVisible={isVisible5}
          />
          <MakeMoney currentElement={currentElement6} isVisible={isVisible6} />
          <RaiseFunds currentElement={currentElement7} isVisible={isVisible7} />
        </div>
      </div>
    </div>
  )
}

export default StartupLibraryBlockSteps
