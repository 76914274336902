import React from 'react'

import articles from '../../../assets/images/png/startupLibraryPage/Articles.png'
import books from '../../../assets/images/png/startupLibraryPage/Books.png'
import time from '../../../assets/images/png/startupLibraryPage/TimeCircle.png'
import videos from '../../../assets/images/png/startupLibraryPage/Play.png'
import profile from '../../../assets/images/png/startupLibraryPage/Profile.png'
import arrow from '../../../assets/images/png/startupLibraryPage/arrowLine.png'

import * as styles from './styles.module.scss'

const Articles = [
  {
    id: 1555555,
    title: '4 Definitive Ways to Grow Your Startup.',
    text:
      'Four factors that can help you influence growth in your own startup.',
    time: '5 min to read',
    author: 'Rahul Varshneya',
    button: arrow,
    link: 'https://www.entrepreneur.com/article/249314',
  },
  {
    id: 258989898,
    title: 'How to Grow Your Business.',
    text: 'Here’s how you can take your business to the next level.',
    time: '13 min to read',
    author: 'Kristin Wong',
    button: arrow,
    link: 'https://www.nytimes.com/guides/business/how-to-grow-your-business',
  },
]

const Books = [
  {
    id: 158092390843289023,
    title:
      'The Four Steps to the Epiphany: Successful Strategies for Startups...',
    text:
      'Step-by-step strategy of how to successfully organize sales and marketing.',
    time: '281 pages to read',
    author: 'Steve Blank',
    button: arrow,
    link:
      'https://www.goodreads.com/book/show/762542.The_Four_Steps_to_the_Epiphany',
  },
  {
    id: 25902389032,
    title: 'Traction: How Any Startup Can Achieve Explosive Customer Growth.',
    text:
      'This book will help you create and sustain the growth your business desperately needs.',
    time: '240 pages to read',
    author: 'Gabriel Weinberg',
    button: arrow,
    link:
      'https://www.amazon.com/Traction-Startup-Achieve-Explosive-Customer/dp/1591848369',
  },
]

const Videos = [
  {
    id: 15282828282,
    title: 'Five Tips To Follow While Scaling Up in Volatile Market.',
    text:
      'Here are 5 surefire ways that every startup entrepreneur should follow in order to expand their business.',
    time: '2 min to watch',
    author: 'Kamalika De',
    button: arrow,
    link: 'https://www.entrepreneur.com/article/313812',
  },
  {
    id: 25555588888,
    title: 'How to Grow Your Startup With Virtual Teams.',
    text: 'The best techniques to scale up the startup.',
    time: '15 min to watch',
    author: 'Manuel Pistner',
    button: arrow,
    link:
      'https://www.youtube.com/watch?v=hd2ILj6B-D4&list=PLId47tdEfIw_hkOTwT-yMxANbl61mY9xU&t=1s',
  },
]

const GrowStartup = ({ currentElement }) => {
  return (
    <div className={styles.startupLibraryBlock}>
      <div
        className={styles.startupLibraryBlockArticles}
        id={'grow-the-startup'}
      >
        <div
          className={styles.startupLibraryBlockArticlesPage}
          ref={currentElement}
        >
          <div>
            <div>
              <h2>Grow the Startup</h2>
              <div className={styles.startupLibraryBlockArticlesPageTitle}>
                <img src={articles} alt="Articles" />
                <h3>Articles</h3>
              </div>
            </div>
            {Articles?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={books} alt="Books" />
              <h3>Books</h3>
            </div>
            {Books?.map((el) => (
              <div key={el.id}  className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={videos} alt="Videos" />
              <h3>Videos</h3>
            </div>
            {Videos?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GrowStartup
