import React from 'react'
import { Link } from 'gatsby'

import startup from '../../../assets/images/png/startupLibraryPage/Startup.png'
import * as styles from './styles.module.scss'

const StartupLibraryBlockTitle = () => {
  return (
    <div className={styles.startupLibraryBlock}>
      <div className={styles.startupLibraryBlockPage}>
        <div className={styles.startupLibraryBlockPageContent}>
          <div className={styles.startupLibraryBlockPageContentTitle}>
            <h2>Startup Library</h2>
            <div className={styles.startupLibraryBlockPageContentTitleMobile}>
              <p>
                Learn how to start a company, with help from the our Startup Library. The Startup Library contains the most valuable advice in the
                articles, books and videos.
              </p>
              <Link to="/startup-library/#start-here">
                <button>Get started</button>
              </Link>
            </div>
          </div>
          <img src={startup} alt="Startup Library" />
        </div>
      </div>
    </div>
  )
}

export default StartupLibraryBlockTitle
