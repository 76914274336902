import React from 'react'

import articles from '../../../assets/images/png/startupLibraryPage/Articles.png'
import books from '../../../assets/images/png/startupLibraryPage/Books.png'
import time from '../../../assets/images/png/startupLibraryPage/TimeCircle.png'
import videos from '../../../assets/images/png/startupLibraryPage/Play.png'
import profile from '../../../assets/images/png/startupLibraryPage/Profile.png'
import arrow from '../../../assets/images/png/startupLibraryPage/arrowLine.png'

import * as styles from './styles.module.scss'

const Articles = [
  {
    id: 1999999,
    title: 'How to Get Funding for Your Business.',
    text: 'Most healthy businesses need business financing at some point.',
    time: '10 min to read',
    author: 'Tim Berry',
    button: arrow,
    link: 'https://articles.bplans.com/how-to-get-your-business-funded/',
  },
  {
    id: 2939393,
    title: 'The 10 Most Reliable Ways to Fund a Startup.',
    text: 'Advices how you can find the money to start your business.',
    time: '5 min to read',
    author: 'Martin Zwilling',
    button: arrow,
    link: 'https://www.entrepreneur.com/slideshow/299773',
  },
]

const Books = [
  {
    id: 195243512,
    title: 'The Startup Game: Inside the Partnership between Venture...',
    text:
      'Inside the partnership between venture capitalists and entrepreneurs',
    time: '272 pages to read',
    author: 'William H. Draper III',
    button: arrow,
    link:
      'https://www.amazon.com/Startup-Game-Partnership-Capitalists-Entrepreneurs/dp/0230339948',
  },
  {
    id: 2963246234623,
    title: 'Pitch Anything: An Innovative Method for Presenting, Persuading...',
    text: 'Pitching is an essential skill for entrepreneurs.',
    time: '240 pages to read',
    author: 'Oren Klaff',
    button: arrow,
    link:
      'https://www.amazon.com/Pitch-Anything-Innovative-Presenting-Persuading/dp/0071752854/ref=sr_1_1?dchild=1&keywords=Pitch+Anything%3A+An+Innovative+Method+for+Presenting%2C+Persuading%2C+and+Winning+the+Deal&qid=1629380363&s=books&sr=1-1',
  },
]

const Videos = [
  {
    id: 1923452345346,
    title: 'Startup Funding Explained: Everything You Need to Know.',
    text: 'Funding is an important stepping stone for any startup.',
    time: '9 min to watch',
    author: 'Gorish Dua',
    button: arrow,
    link: 'https://www.youtube.com/watch?v=677ZtSMr4-4&t=2s',
  },
  {
    id: 2943252342345,
    title: 'How To Fund Your Startup.',
    text: '8 different options for funding your startup.',
    time: '13 min to watch',
    author: 'Dan Martell',
    button: arrow,
    link: 'https://www.youtube.com/watch?v=8-9176AxXVo',
  },
]

const RaiseFunds = ({ currentElement }) => {
  return (
    <div className={styles.startupLibraryBlock}>
      <div className={styles.startupLibraryBlockArticles} id={'raise-funds'}>
        <div
          className={styles.startupLibraryBlockArticlesPage}
          ref={currentElement}
          style={{paddingBottom: 120}}
        >
          <div>
            <div>
              <h2>Raise Funds</h2>
              <div className={styles.startupLibraryBlockArticlesPageTitle}>
                <img src={articles} alt="Articles" />
                <h3>Articles</h3>
              </div>
            </div>
            {Articles?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={books} alt="Books" />
              <h3>Books</h3>
            </div>
            {Books?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={videos} alt="Videos" />
              <h3>Videos</h3>
            </div>
            {Videos?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RaiseFunds
