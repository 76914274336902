import { createRef, useEffect, useState } from 'react'

export default function useVisibility(offset = 0) {
  const [isVisible, setIsVisible] = useState(false)
  const currentElement = createRef()

  const onScroll = () => {
    if (!currentElement.current) {
      setIsVisible(false)
      return
    }
    let top = currentElement.current.getBoundingClientRect().top + 1130
    setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight + 1130)
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true)
    return () => document.removeEventListener('scroll', onScroll, true)
  })

  return [isVisible, currentElement]
}
