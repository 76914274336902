import React from 'react'

import articles from '../../../assets/images/png/startupLibraryPage/Articles.png'
import books from '../../../assets/images/png/startupLibraryPage/Books.png'
import time from '../../../assets/images/png/startupLibraryPage/TimeCircle.png'
import videos from '../../../assets/images/png/startupLibraryPage/Play.png'
import profile from '../../../assets/images/png/startupLibraryPage/Profile.png'
import arrow from '../../../assets/images/png/startupLibraryPage/arrowLine.png'

import * as styles from './styles.module.scss'

const Articles = [
  {
    id: 16666666,
    title: '3 Ways to Make Money and Build Your Business.',
    text:
      'These strategies can help you use your limited time and resources to start your business.',
    time: '11 min to read',
    author: 'Matthew Mc.Creary',
    button: arrow,
    link: 'https://www.entrepreneur.com/article/364437',
  },
  {
    id: 2676767676,
    title: '9 Ways Entrepreneurs Can Get Started Making Money in a Hurry.',
    text:
      'A few different enterprises that each make some money can add up to you making a lot of money.',
    time: '6 min to read',
    author: 'Murray Newlands',
    button: arrow,
    link: 'https://www.entrepreneur.com/article/308455',
  },
  {
    id: 36768499494,
    title: 'How to Get Funding for Your Business.',
    text: 'NMost healthy businesses need business financing at some point.',
    time: '10 min to read',
    author: 'Tim Berry',
    button: arrow,
    link: 'https://articles.bplans.com/how-to-get-your-business-funded/',
  },
]

const Books = [
  {
    id: 16888888,
    title: 'Start Something That Matters.',
    text: 'The incredible story of the man behind TOMS Shoes and One for One.',
    time: '224 pages to read',
    author: 'Blake Mycoskie',
    button: arrow,
    link:
      'https://www.amazon.com/Start-Something-Matters-Blake-Mycoskie/dp/0812981448',
  },
  {
    id: 2694030309,
    title: 'Superbosses: How Exceptional Leaders Master the Flow of Talent.',
    text: 'This book is chock full of new, useful, and often unexpected ideas.',
    time: '272 pages to read',
    author: 'Sydney Finkelstein',
    button: arrow,
    link:
      'https://www.amazon.com/Superbosses-Exceptional-Leaders-Master-Talent/dp/1591847834',
  },
]

const Videos = [
  {
    id: 16823942831049,
    title: '5 Ways To Raise Money For Your Business.',
    text: 'Here are 5 ways to get money for your business.',
    time: '15 min to watch',
    author: 'Simon Squibb',
    button: arrow,
    link: 'https://www.youtube.com/watch?v=yIaOKefau7w',
  },
  {
    id: 261248930129834120,
    title: 'How Startup Investment Works?',
    text: 'How to invest in startups.',
    time: '8 min to watch',
    author: 'Alex Berman',
    button: arrow,
    link: 'https://www.youtube.com/watch?v=Jk97jfPle5A',
  },
]

const MakeMoney = ({ currentElement }) => {
  return (
    <div className={styles.startupLibraryBlock}>
      <div className={styles.startupLibraryBlockArticles} id={'make-money'}>
        <div
          className={styles.startupLibraryBlockArticlesPage}
          ref={currentElement}
        >
          <div>
            <div>
              <h2>Make Money</h2>
              <div className={styles.startupLibraryBlockArticlesPageTitle}>
                <img src={articles} alt="Articles" />
                <h3>Articles</h3>
              </div>
            </div>
            {Articles?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={books} alt="Books" />
              <h3>Books</h3>
            </div>
            {Books?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={videos} alt="Videos" />
              <h3>Videos</h3>
            </div>
            {Videos?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MakeMoney
