import React from 'react'

import articles from '../../../assets/images/png/startupLibraryPage/Articles.png'
import books from '../../../assets/images/png/startupLibraryPage/Books.png'
import time from '../../../assets/images/png/startupLibraryPage/TimeCircle.png'
import videos from '../../../assets/images/png/startupLibraryPage/Play.png'
import profile from '../../../assets/images/png/startupLibraryPage/Profile.png'
import arrow from '../../../assets/images/png/startupLibraryPage/arrowLine.png'

import * as styles from './styles.module.scss'

const Articles = [
  {
    id: 1235344536349,
    title: 'How to Get Startup Ideas.',
    text:
      'YC Partner Jared Friedman shares advice on how to get startup ideas.',
    time: '5 min to read',
    author: 'Jared Friedman',
    button: arrow,
    link: 'https://www.ycombinator.com/library/8g-how-to-get-startup-ideas',
  },
  {
    id: 2254654745734,
    title: 'How to Develop a Great Startup Idea.',
    text: 'Some useful advices how to develop a great startup idea.',
    time: '4 min to read',
    author: 'Sterling Seizert',
    button: arrow,
    link:
      'https://medium.com/swlh/how-to-develop-a-great-startup-idea-5e55f9fedbf2',
  },
  {
    id: 3256345645,
    title: 'How to Come up with Hundreds of Business Ideas.',
    text: '11 advices to come up with hundreds of business ideas',
    time: '17 min to read',
    author: 'Candice Landau',
    button: arrow,
    link:
      'https://articles.bplans.com/business-ideas/create-hundred-of-business-ideas/',
  },
]

const Books = [
  {
    id: 126675684586,
    title: 'Small Business Operator.',
    text:
      'This book is one of the most popular guides for starting a business. ',
    time: '280 pages to read',
    author: 'Bernard B. Kamoroff',
    button: arrow,
    link:
      'https://www-amazon-com.translate.goog/Small-Time-Operator-Business-Trouble/dp/163076261X/?tag=smallbusin0b3-20&_x_tr_sl=en&_x_tr_tl=ru&_x_tr_hl=ru&_x_tr_pto=ajax,se,elem,sc',
  },
  {
    id: 22546346356899,
    title: 'Idea Craft.',
    text: 'Discover the best small business idea for you.',
    time: '82 pages to read',
    author: 'Sarah Korhnak',
    button: arrow,
    link:
      'https://www.amazon.com/Idea-Craft-Discover-Small-Business-ebook/dp/B01N0CHQ5G/ref=sr_1_1?dchild=1&keywords=Idea+Craft&qid=1629368291&s=books&sr=1-1',
  },
]

const Videos = [
  {
    id: 12899999,
    title: 'Need a Business Idea?',
    text: 'The best way to find one is to stop looking.',
    time: '10 min to watch',
    author: 'Gary Vaynerchuk',
    button: arrow,
    link: 'https://www.entrepreneur.com/video/295451',
  },
  {
    id: 2264357999996,
    title: 'What’s the best way to find a business idea?',
    text: 'Finding a business idea - is the single biggest barrier for people.',
    time: '11 min to watch',
    author: 'Ramit Sethi',
    button: arrow,
    link: 'https://growthlab.com/whats-the-best-way-to-find-a-business-idea/',
  },
]

const LaunchIdea = ({ currentElement }) => {
  return (
    <div className={styles.startupLibraryBlock}>
      <div className={styles.startupLibraryBlockArticles} id={'launch-an-idea'}>
        <div
          className={styles.startupLibraryBlockArticlesPage}
          ref={currentElement}
        >
          <div>
            <div>
              <h2>Launch an Idea</h2>
              <div className={styles.startupLibraryBlockArticlesPageTitle}>
                <img src={articles} alt="Articles" />
                <h3>Articles</h3>
              </div>
            </div>
            {Articles?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={books} alt="Books" />
              <h3>Books</h3>
            </div>
            {Books?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={videos} alt="Videos" />
              <h3>Videos</h3>
            </div>
            {Videos?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LaunchIdea
