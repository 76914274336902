// extracted by mini-css-extract-plugin
export var startupLibraryBlock = "styles-module--startupLibrary-block--tOk6F";
export var startupLibraryBlockPage = "styles-module--startupLibrary-block-page--IovSN";
export var startupLibraryBlockPageContent = "styles-module--startupLibrary-block-page-content--jO8cV";
export var startupLibraryBlockPageContentTitle = "styles-module--startupLibrary-block-page-content-title--brXM7";
export var startupLibraryBlockParts = "styles-module--startupLibrary-block-parts--e6maw";
export var startupLibraryBlockPartsSteps = "styles-module--startupLibrary-block-parts-steps--W1ScC";
export var startupLibraryBlockPartsStepsNecessary = "styles-module--startupLibrary-block-parts-steps-necessary--AFYiT";
export var startupLibraryBlockArticles = "styles-module--startupLibrary-block-articles--IouWF";
export var startupLibraryBlockArticlesPage = "styles-module--startupLibrary-block-articles-page--xQ05I";
export var startupLibraryBlockArticlesPageTitle = "styles-module--startupLibrary-block-articles-page-title--KAnD1";
export var startupLibraryBlockArticlesPageBlock = "styles-module--startupLibrary-block-articles-page-block--AxIYk";
export var startupLibraryBlockArticlesPageBlockContent = "styles-module--startupLibrary-block-articles-page-block-content--luHxe";
export var startupLibraryBlockArticlesPageBlockContentIcon = "styles-module--startupLibrary-block-articles-page-block-content-icon--vimSZ";
export var startupLibraryBlockArticlesPageBlockContentIconTime = "styles-module--startupLibrary-block-articles-page-block-content-icon-time--lHPxm";
export var startupLibraryBlockArticlesPageBlockContentIconProfile = "styles-module--startupLibrary-block-articles-page-block-content-icon-profile--aEirD";
export var startupLibraryBlockPartsStepsNecessaryTab = "styles-module--startupLibraryBlockPartsStepsNecessaryTab--nf6mD";
export var startupLibraryBlockPartsStepsNecessaryFixed = "styles-module--startupLibraryBlockPartsStepsNecessaryFixed--p7PLk";
export var startupLibraryBlockPartsStepsNecessaryAbsolut = "styles-module--startupLibraryBlockPartsStepsNecessaryAbsolut--WFGtO";
export var startupLibraryBlockPageContentTitleMobile = "styles-module--startupLibraryBlockPageContentTitleMobile--buFQP";