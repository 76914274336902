import React from 'react'

import Seo from '../../components/Seo'
import StartupLibraryBlockTitle from '../../components/mainComponents/StartupLibraryBlock/StartupLibraryBlockTitle'
import StartupLibraryBlockSteps from '../../components/mainComponents/StartupLibraryBlock/StartupLibraryBlockSteps'

import contactsImage from '../../assets/images/jpg/seo/Contacts-min.jpg'

const Index = () => {
  return (
    <>
      <Seo
        title="Startup library | Justice IT"
        htmlAttributes={{
          lang: 'en',
        }}
        url="https://www.it-justice.com/"
        description=" Learn how to start a company, with help from the our Startup Library. The Startup Library contains the most valuable advice in the
                articles, books and videos."
        image={contactsImage}
        keywords={[
          'start your own business',
          'startup library',
          'start a company',
          'start building company',
        ]}
      />
      <div>
        <StartupLibraryBlockTitle />
        <StartupLibraryBlockSteps />
      </div>
    </>
  )
}

export default Index
