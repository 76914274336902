import React from 'react'

import articles from '../../../assets/images/png/startupLibraryPage/Articles.png'
import books from '../../../assets/images/png/startupLibraryPage/Books.png'
import time from '../../../assets/images/png/startupLibraryPage/TimeCircle.png'
import videos from '../../../assets/images/png/startupLibraryPage/Play.png'
import profile from '../../../assets/images/png/startupLibraryPage/Profile.png'
import arrow from '../../../assets/images/png/startupLibraryPage/arrowLine.png'

import * as styles from './styles.module.scss'

const Articles = [
  {
    id: 14568655623,
    title: 'A Step-by-Step Guide to Build a MVP.',
    text: 'How to build a minimum viable product .',
    time: '20 min to read',
    author: 'Amit Manchanda ',
    button: arrow,
    link:
      'https://www.netsolutions.com/insights/how-to-build-an-mvp-minimum-viable-product-a-step-by-step-guide/',
  },
  {
    id: 24756565213,
    title: 'Everything You Need to Know for Building MVP.',
    text:
      'Building a minimum viable product can take multiple iterations to reach completion.',
    time: '9 min to read',
    author: 'Arsalan Sajid',
    button: arrow,
    link: 'https://www.cloudways.com/blog/building-an-mvp/',
  },
  {
    id: 346896960444,
    title: 'Why do startups need to start with a Minimum Viable Product?',
    text:
      'MVP will help you validate your business ideas in the early days of your startup.',
    time: '10 min to read',
    author: 'Dmitry Chekalin',
    button: arrow,
    link:
      'https://www.itproportal.com/features/why-do-startups-need-to-start-with-a-minimum-viable-product/',
  },
]

const Books = [
  {
    id: 144444444,
    title: 'Minimum Viable Product: Master Early Learning and Develop...',
    text: 'Discover the secrets to creating a MVP in no time!',
    time: '43 pages to read',
    author: 'Scott Grossman',
    button: arrow,
    link:
      'https://www.amazon.com/Minimum-Viable-Product-Learning-Develop-ebook/dp/B06X939XD3',
  },
  {
    id: 24686896969,
    title: 'Startup Resource Guide: How to launch an MVP and Advance...',
    text: 'This guide is comprised of twelve chapters.',
    time: '83 pages to read',
    author: 'Michael O`Donnell',
    button: arrow,
    link:
      'https://www.amazon.com/Startup-Resource-Guide-Business-StartupBiz-ebook/dp/B013NO4MTE/ref=sr_1_1?dchild=1&keywords=mvp+startup&qid=1629372136&s=digital-text&sr=1-1',
  },
]

const Videos = [
  {
    id: 147373737373,
    title: 'How to Plan an MVP.',
    text:
      'How to building an MVP and getting your first users as a pre-launch startup.',
    time: '14 min to watch',
    author: 'Michael Seibel',
    button: arrow,
    link: 'https://www.ycombinator.com/library/6f-how-to-plan-an-mvp',
  },
  {
    id: 24737373882990,
    title: 'What`s the cost of your MVP?',
    text:
      'The main thing about creating an MVP is that just having a groundbreaking idea is not enough to ensure success.',
    time: '2 min to watch',
    author: 'Maksym Babych',
    button: arrow,
    link: 'https://www.youtube.com/watch?v=waN7Iz_nSYk',
  },
]

const CreateMVP = ({ currentElement }) => {
  return (
    <div className={styles.startupLibraryBlock}>
      <div className={styles.startupLibraryBlockArticles} id={'create-an-mvp'}>
        <div
          className={styles.startupLibraryBlockArticlesPage}
          ref={currentElement}
        >
          <div>
            <div>
              <h2>Create an MVP</h2>
              <div className={styles.startupLibraryBlockArticlesPageTitle}>
                <img src={articles} alt="Articles" />
                <h3>Articles</h3>
              </div>
            </div>
            {Articles?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={books} alt="Books" />
              <h3>Books</h3>
            </div>
            {Books?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
            <div className={styles.startupLibraryBlockArticlesPageTitle}>
              <img src={videos} alt="Videos" />
              <h3>Videos</h3>
            </div>
            {Videos?.map((el) => (
              <div key={el.id} className={styles.startupLibraryBlockArticlesPageBlock}>
                <div
                  className={styles.startupLibraryBlockArticlesPageBlockContent}
                >
                  <h3>{el.title}</h3>
                  <p>{el.text}</p>
                  <div
                    className={
                      styles.startupLibraryBlockArticlesPageBlockContentIcon
                    }
                  >
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconTime
                      }
                    >
                      <img src={time} alt="Time icon" />
                      <p>{el.time}</p>
                    </div>
                    <div
                      className={
                        styles.startupLibraryBlockArticlesPageBlockContentIconProfile
                      }
                    >
                      <img src={profile} alt="Profile icon" />
                      <p>{el.author}</p>
                    </div>
                  </div>
                </div>
                <a href={el.link}>
                  <button>
                    <img src={el.button} />
                  </button>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateMVP
